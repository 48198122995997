$inputWidth: 440px;
$mobileInputWidth: 400px;

.settings-page {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  padding: 50px 5rem 50px 0;

  @media screen and (max-width: 1200px) {
    padding: 50px 5rem;
  }

  @media screen and (max-width: 490px) {
    padding: 50px 8%;
  }

  .content-container {
    h2 {
      margin-top: 0;
    }

    @media screen and (max-width: 610px) {
      width: 100%;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 40px;

    label p {
      color: $inputDarkGrey;
      font-size: 16px;
      line-height: 22px;
      margin-top: 0;
    }
  
    input {
      background: $inputAreaLight;
      border: 1px solid $inputDarkGrey;
      border-radius: 10px;
      padding: 0px 20px;
      max-height: 50px;
      max-width: $inputWidth;

      @media screen and (max-width: 500px) {
        max-width: $mobileInputWidth;
      }
    }
  
    input[type="text"],
    input[type="password"] {
      color: $textBlack;
      font-size: 16px;
      line-height: 22px;
    }

    p.error {
      color: $textRed;
      font-size: 14px;
      font-weight: 700;
      margin: 5px 0 0;
    }
  }

  .name-container {
    align-items: flex-start;
    display: flex;
    gap: 40px;
    
    input {
      max-width: 200px;
    };

    @media screen and (max-width: 500px) {
      align-items: initial;
      flex-direction: column;

      input {
        max-width: $mobileInputWidth;
      }
    }
  }

  .password-container {
    position: relative;

    input {
      max-width: 440px;
      padding-right: 50px;
    }

    button {
      right: 18px;
      float: right;
      position: absolute;
      top: 6px;
      z-index: 1;
    }

    @media screen and (max-width: 610px) {
      button {
        right: 24px;
      }
    }

    @media screen and (max-width: 500px) {
      input {
        max-width: $mobileInputWidth;
      }
    }
  }

  .buttons-container {
    align-items: baseline;
    display: flex;
    gap: 50px;

    @media screen and (max-width: 610px) {
      flex-direction: column;
      gap: 20px;
    }
  }
}