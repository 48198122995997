.breadcrumb {
  font-size: 20px;
  line-height: 33px;

  a {
    color: $textBlack;
    text-decoration: none;
    text-transform: capitalize;
  }

  .active {
    font-weight: 600;
  }
}