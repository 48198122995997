.Sidebar {
  p.title {
    font-size: 24px;
    font-weight: 600;
    margin: 0 0 1.5rem 2rem;
  }

  p.tab-item {
    font-size: 18px;
    margin: 0;
  }

  .tab {
    padding: 1rem 2rem;
    margin: 0;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    transition: 0.3s ease-in;
    text-decoration: none;
    color: black;

    &:hover {
      background-color: $primaryLight;
    }

    img {
      margin-right: 2rem;
      height: 25px;
      width: 25px;
      align-self: center;
    }
  }
}

.Sidebar.onPage {
  background-color: $lightWhite;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  height: 685px;
  width: 13rem;
  padding: 1.5rem 0;
  margin: 0 5rem;
  min-width: 204px;
  z-index: 10;

  .tab {
    &.active {
      background-color: $primaryLight;
    }
  
    &.logout {
      margin-top: auto;
      margin-bottom: 0;
    }
  }

  @media screen and (max-width: 1200px) {
    display: none;
  }
}

.Sidebar.mobile {
  @media screen and (min-width: 1201px) {
    display: none;
  }

  @media screen and (max-width: 1200px) {
    background-color: rgba(42, 49, 108, 0.2);
    border-radius: 0;
    color: white;
    float: right;
    height: 100%;
    margin: 0;
    position: absolute;
    top: 0;
    right: -100vw;
    backdrop-filter: blur(9px);
    padding: 0;
    width: 15rem;
    min-width: 204px;
    z-index: 10;
    transition: right 1s;

    div.filter {
      background-color: rgba(42, 49, 108, 0.8);
      backdrop-filter: blur(9px);
      mix-blend-mode: multiply;
      margin: 0;
      height: 100%;
      padding: 1.5rem 0;
    }

    .title-container {
      margin-top: 76px;
    }

    .tab {
      transition: 0.3s ease-in;
      color: white;

      img {
        filter: invert(93%) sepia(99%) saturate(4%) hue-rotate(193deg) brightness(105%) contrast(100%);
      }

      &:hover {
        background-color: $gradientBlue;
      }

      &.active {
        background-color: $gradientBlue;
      }

      &.logout {
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    &.open {
      right: 0;
      transition: right 0.8s;
    }
  }
}
