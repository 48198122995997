.modal {
  align-items: center;
  backdrop-filter: blur(9px);
  background: rgba(42, 49, 108, 0.3);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1000;

  .content-container {
    align-items: center;
    background-color: $lightWhite;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 60px;
    width: 550px;

    @media screen and (max-width: 450px) {
      padding: 60px 20px;
    }

    p {
      font-size: 24px;
      font-weight: 700;
      line-height: 33px;
      margin: 0;
      text-align: center;
    }

    .badge {
      margin: 30px 0;

      @media screen and (max-width: 600px) {
        svg {
          height: 100px;
          width: 100px;
        }
      }
    }
  }
}