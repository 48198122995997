.AdminPage {
  display: flex;
  flex-direction: row;

  .admin-page-content {
    margin-top: 50px;
    width: 100%;
  }
  
  .title {
    font-size: 24px;
    font-weight: 600;
  }

  table.admin {
    width: 80%;
    margin-bottom: 3rem;
    
    th {
      padding: 0 0 0.5rem 0;
      text-align: left;
    }

    .admin-submit {
      color: $lightWhite;
      background-color: $inputSubmitActive;
      border-radius: 5px;
      font-weight: bold;
      font-size: 15px;
      text-align: center;
      margin-top: 1rem;
    }

    .admin-remove-button {
      color: $lightWhite;
      background-color: $inputSubmitActive;
      border-radius: 5px;
      font-weight: bold;
      font-size: 14px;
      text-align: center;
    }
  }

  form.admin-add-team-form {
    label {
      font-weight: bold;
    }

    .admin-input-container {
      margin: 1rem 0 0 0;

      input[type=text] {
        background-color: $lightWhite;
        width: 30%;
        height: 40px;
      }

      input[type=submit] {
        margin: 0 0 0 0.5rem;
        font-size: 15px;
        height: 40px!important;
        min-height: 40px!important;
      }

      display: flex;
      flex-direction: row;
    }

  }
}