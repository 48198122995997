.login-page,
.signup-page {
  display: flex;
  flex-direction: row;

  .container-description {
    background-image: linear-gradient($gradientBlue, $gradientBlue), url("../../assets/bg-login.png");
    background-color: white;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-basis: 70%;
    flex-direction: column;
    justify-content: space-between;

    .logo {
      margin: 40px 0 0 40px;
      width: 150px;
    }

    .description {
      margin: 40% 60px 20%;

      h1 {
        font-weight: 400;
        margin: 0 0 30px 0;
      }

      p {
        line-height: 22px;
        margin: 0;
        width: 80%;
      }

      h1, p {
        color: white;
      }

    }

    .attribution {
      display: flex;
      justify-content: start;

      a {
        color: silver;
        font-size: 12px;
        margin: 10px;
      }
    }
  }

  .container-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-basis: 30%;
    margin: 5%;

    h2 {
      text-align: center;
    }

    p,
    a {
      font-weight: 600;
    }

    .additional-links {
      color: $textGrey;
      font-size: 24px;
      margin: 24px auto 0;
      text-align: center;
    }

    .form {
      display: flex;
      flex-direction: column;
      width: 450px;
      margin: 0 auto;

      @media screen and (max-width: 480px) {
        width: 320px;
      }
    }

    .submit-btn-container {
      display: flex;
      flex-direction: column;
      margin: 50px 0 20px;

      .error {
        color: $textRed;
        margin: 0;
      }
      
      input {
        flex-grow: 1;
      }
    }
  }

  @media screen and (max-width: $breakpoint1) {
    .container-form {
      margin: 3%;
    }
  }

  @media screen and (max-width: $breakpoint2) {
    flex-direction: column;

    .container-description {
      .logo {
        margin: 40px 0 0 5%;
      }

      .description {
        margin: 40px 5%;

        h1 {
          font-size: 40px;
        }

        p {
          width: 100%;
        }
      }
    }

    .container-form {
      padding: 0 0 60px;

      .additional-links {
        font-size: 18px;
      }
    }
  }
}

.login-page {
  @media screen and (min-height: 730px) {
    height: 100vh;
  }

  .container-form .form .input-password-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      display: inline-block;
    }

    a {
      color: $textGrey;
    }
  }
}

.signup-page {
  @media screen and (min-height: 900px) {
    height: 100vh;
  }

  .container-form form .name {
    display: flex;
    gap: 20px;

    label {
      flex-basis: 50%;
    }

    @media screen and (max-width: $breakpoint3) {
      flex-direction: column;
      gap: 0;

      label {
        flex-basis: 100%;
      }
    }
  }
}