.navigation {
  align-items: center;
  background-color: $primaryBlue;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px 10px 80px;
  width: 100%;

  @media screen and (max-width: 700px) {
    padding: 10px 18px;
  }

  .logo {
    width: 154px;
  }

  .menu-button {
    background: transparent;
    border: none;
    cursor: pointer;
    position: relative;
    z-index: 10000;

    svg {
      fill: $lightWhite;
      height: 36px;
      width: 36px;
    }

    @media screen and (min-width: 1201px) {
      display: none;
    }
  }
}