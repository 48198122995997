.front-page {
  display: flex;
  flex-direction: row;
  padding: 50px 5rem 50px 0;

  @media screen and (max-width: 1200px) {
    padding: 50px 5rem;
  }

  @media screen and (max-width: 700px) {
    padding: 50px 18px;
  }

  .badge-cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 50px;
    justify-content: space-between;
    max-width: 1070px;

    @media screen and (max-width: 970px) {
      align-items: center;
      flex-direction: column;
    }
  }
}