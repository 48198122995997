.action-card {
  border: none;
  border-radius: 10px;
  background-color: white;
  position: relative;
  overflow: hidden;
  min-height: 138px;
  height: auto;
  width: 300px;
  max-width: 300px;
  margin-bottom: 40px;
  transition: transform 0.2s ease-in-out;

  &:hover {
    box-shadow: 0px 2px 20px 10px rgba(42, 49, 108, 0.15);
    transform: translateY(-9px);
  }

  .content-container {
    align-items: center;
    display: flex;
    padding: 20px 25px 50px;
    gap: 20px;
    position: relative;
    overflow: hidden;
    z-index: 1;

    .checkbox {
      display: inline-block;
      border: 2px solid $grey;
      border-radius: 2px;
      height: 15px;
      overflow: hidden;
      position: relative;
      min-width: 15px;
      width: 15px;
    }

    p {
      margin: 0;
      text-align: left;
      white-space: normal;
      word-wrap: normal;
    }
  }

  img.icon {
    bottom: 14px;
    right: 16px;
    filter: invert(100%);
    position: absolute;
    z-index: 1;
  }

  &::after {
    position: absolute;
    content: "";
    background-color: $grey;
    border-radius: 100px;
    bottom: -74px;
    right: -64px;
    height: 132px;
    width: 132px;
  }
}

.action-card.selected {
  color: $textWhite;

  .checkbox {
    border: 2px solid $lightWhite;
  }

  .checkmark {
    top: -2px;
    left: -3px;
    position: absolute;
  }

  &::after {
    background-color: $lightWhite;
  }
}

.action-card.seed.selected {
  background-color: $seedBackground;

  img.icon {
    filter: invert(19%) sepia(25%) saturate(7084%) hue-rotate(194deg) brightness(90%) contrast(96%);
  }
}

.action-card.sprout.selected {
  background-color: $sproutBackground;

  img.icon {
    filter: invert(33%) sepia(7%) saturate(596%) hue-rotate(191deg) brightness(88%) contrast(86%);
  }
}

.action-card.grow.selected {
  background-color: $growBackground;

  img.icon {
    filter: invert(9%) sepia(65%) saturate(7188%) hue-rotate(354deg) brightness(84%) contrast(103%);
  }
}

.action-card.thrive.selected {
  background-color: $thriveBackground;

  img.icon {
    filter: invert(49%) sepia(87%) saturate(2219%) hue-rotate(29deg) brightness(100%) contrast(101%);
  }
}