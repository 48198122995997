.TeamBadge {
  background-color: $primaryBlue;
  border-radius: 10px;
  height: 160px;
  padding: 25px;
  width: 320px;

  .name {
    font-size: 20px;
    font-weight: bold;
    margin: 0;
    text-align: center;
  }

  .badge-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 25px;

    .badge svg {
      height: 50px;
      width: 50px;
    }
  }

  p {
    color: white;
  }
}