.select-actions-page {

  .action-options {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 760px) {
      flex-direction: column;
    }

    p {
      color: $textBlack;
      font-size: 20px;
    }

    .action-filters {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 10px;
    }
  }

  .action-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-evenly;
    padding: 30px 0;
  }
}