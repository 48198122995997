.banner {
  align-items: center;
  background-color: $primaryBlue;
  border-radius: 15px;
  color: $textWhite;
  display: flex;
  justify-content: space-between;
  padding: 10px 40px;
  height: auto;
  max-width: 1070px;

  .content-container {
    height: auto;
    width: 65%;

    h3 {
      line-height: 33px;
    }
  }

  @media screen and (max-width: 600px) {
    align-items: flex-start;
    padding: 10px 18px;

    .badge {
      margin-top: 20px;
      svg {
        height: 60px;
        width: 60px;
      }
    }
  }
}