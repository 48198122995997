.actions-page {
  .header-container {
    align-items: baseline;
    display: flex;
    justify-content: space-between;
    
    .edit-actions {
      display: inline-flex;
      font-size: 16px;
      text-decoration: none;

      &::before {
        content: url("../../assets/edit.svg");
        margin-right: 10px;
      }

      &:visited {
        color: $textBlack;
      }
    }
  }

  .action-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;

    @media screen and (max-width: 1360px) {
      justify-content: space-evenly;
    }
  }

  .reflections {
    margin: 50px 0;
    h3 {
      align-items: center;
      display: inline-flex;

      &::after {
        content: url("../../assets/lock_open.svg");
        margin-left: 30px;
      }
    }

    textarea {
      background-color: rgba($color: $lightWhite, $alpha: 0.5);
      border: 1px solid $darkGrey;
      border-radius: 10px;
      display: block;
      font-size: 16px;
      height: 160px;
      line-height: 22px;
      padding: 22px;
      width: 100%;
    }
  }

  .reflections.disable {
    h3 {
      color: $grey;
      
      &::after {
        content: url("../../assets/lock.svg");
      }
    }

    textarea {
      background-color: rgba($color: $lightWhite, $alpha: 0.5);
      border: 1px solid $grey;
    }
  }

  .btn.submit {
    float: right;
  }
}