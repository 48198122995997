input {
  border: 0;
}

input[type=text],
input[type=email],
input[type=password] {
  background-color: $inputArea;
  border-radius: 10px;
  color: $textBlack;
  font-size: 24px;
  height: 60px;
  padding: 10px;
  width: 100%;

  &:focus-visible {
    outline: $primaryBlue auto 1px;
  }
}

input[type=submit] {
  background-color: $primaryBlue;
  border-radius: 10px;
  color: $textWhite;
  cursor: pointer;
  font-size: 24px;
  font-weight: 700;
  margin: 20px 0px;
  min-height: 60px;
  transition: transform 0.2s ease-in-out;


  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    transform: translateY(-5px);
  }

  &:disabled {
    background-color: $grey;

    &:hover {
      box-shadow: none;
      transform: none;
    }
  }
}

