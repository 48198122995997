.question-card {
  padding: 70px 0 0;
  text-decoration: none;

  .content-container {
    background-color: $lightWhite;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    height: 264px;
    justify-content: space-between;
    overflow: visible;
    position: relative;
    width: 280px;
    padding: 0 20px 0;
    transition: transform 0.2s ease-in-out;

    &:hover {
      box-shadow: 0px 2px 20px 10px rgba(42, 49, 108, 0.15);
      transform: translateY(-9px);
    }

    .badge {
      left: 50%;
      margin-top: -50px;
      position: absolute;
      transform: translateX(-50%);

      @media screen and (max-width: 600px) {
        svg {
          display: block;
          height: 100px;
          width: 100px;
        }
      }
    }

    .question {
      align-items: center;
      color: $textBlack;
      display: flex;
      flex: 1;
      font-size: 20px;
      font-weight: 700;
      justify-content: center;
      line-height: 27px;
      margin: 0;
      padding-top: 55px;
      text-align: center;
    }

    img.status {
      align-self: flex-end;
      filter: brightness(0) saturate(100%) invert(0%) sepia(3%) saturate(1206%) hue-rotate(336deg) brightness(90%) contrast(87%);
      padding-bottom: 20px;
      width: 50px;
    }
  }
}

.question-card.complete {
  .question {
    color: $textWhite;
  }

  img.status {
    filter: none;
  }
}

.question-card.seed.complete .content-container {
  background-color: $seedBackground;
}

.question-card.sprout.complete .content-container {
  background-color: $sproutBackground;
}

.question-card.grow.complete .content-container{
  background-color: $growBackground;
}

.question-card.thrive.complete .content-container{
  background-color: $thriveBackground;
}