button {
  color: black;
  
  &.action-button {
    background-color: $primaryBlue;
    color: white;
    padding: 1rem 2rem;
    border-radius: 6px;
    border: none;
    font-weight: 700;
    font-size: 20px;

    &:hover {
      box-shadow: 0 0 5px black;
    }

    &:active {
      background-color: $inputSubmitActive;
    }
  }
}

.button {
  background-color: $primaryBlue;
  border: none;
  border-radius: 6px;
  color: $textWhite;
  cursor: pointer;
  font-size: 20px;
  font-weight: 700;
  margin: 50px 0px 20px;
  min-height: 60px;
  padding: 18px 28px;
  position: relative;
  transition: transform 0.2s ease-in-out;

  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    transform: translateY(-5px);
  }
}

.button:disabled {
  background-color: $inputSubmitDisabled;
}

.button-link {
  background-color: transparent;
  border: none;
  color: $textBlack;
  cursor: pointer;
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
  text-decoration: underline;
  text-underline-offset: 4px;
}

.btn.primary,
.btn.next {
  background-color: $primaryBlue;
  border: none;
  border-radius: 6px;
  color: $textWhite;
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
  padding: 18px 28px;
  text-align: center;
  text-decoration: none;
  transition: transform 0.2s ease-in-out;

  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    transform: translateY(-5px);
  }

  &:disabled {
    background-color: $grey;

    &:hover {
      box-shadow: none;
      transform: none;
    }
  }
}

.btn.next {
  align-items: center;
  display: flex;
  width: fit-content;

  img.arrow {
    margin-left: 18px;
    width: 24px;
  }
}

.btn.secondary,
.btn.icon {
  background-color: transparent;
  border: none;
  color: $darkGrey;
  font-size: 14px;
  padding: 12px 20px;
  height: 44px;

  &:hover,
  &.active {
    background-color: $lightWhite;
    border-radius: 25px;
  }
}

.btn.icon {
  align-items: center;
  border: none;
  display: flex;

  img.icon {
    filter: invert(43%) sepia(9%) saturate(413%) hue-rotate(193deg) brightness(94%) contrast(88%);
    margin-right: 0.75rem;
    width: 15px;
  }
}