$inputArea: rgba(227, 228, 236, 0.8);
$inputAreaLight: rgba(255, 255, 255, 0.5);;
$inputDarkGrey: #6A6C77;
$inputSubmitActive: #252b5f;
$inputSubmitDisabled: #A9ACBA;

$breakpoint1: 1350px;
$breakpoint2: 900px;
$breakpoint3: 500px;

$textBlack: #101010;
$textBlue: #2A316C;
$textGrey: #909090;
$textWhite: #FDFDFD;
$textRed: red;

$primaryBlue: #2A316C;
$gradientBlue: rgba(42, 49, 108, 0.8);
$lightWhite: #FDFDFD;
$primaryLight: #E8E9EF;
$grey: #A9ACBA;
$darkBlack: #101010;
$darkGrey: #6A6C77;

$seed: #0971CE;
$seedBackground: #054C8B;

$sprout: #FDFDFD;
$sproutBackground: #51535B;

$grow: #ED1C24;
$growBackground: #8E0508;

$thrive: #F0CC00;
$thriveBackground: #AD9400;


