.team-page {
  display: flex;
  flex-direction: row;
  padding: 50px 5rem 50px 0;

  .title {
    margin-top: 0;
  }

  .team-page-content {
    display: flex;
    flex-direction: column;

    .team-badge-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 20px;
    }
  }

  @media screen and (max-width: 1200px) {
    padding: 50px 5rem;
  }

  @media screen and (max-width: 490px) {
    padding: 50px 8%;
  }
}