.full-action-card { 
  align-items: stretch;
  background-color: $lightWhite;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 394px;

  .content-container {
    .image-container {
      border-radius: 20px 20px 0px 0px;
      height: 146px;
      overflow: hidden;
      position: relative;
      width: 100%;

      .image-filter {
        background: $grey;
        height: inherit;
        mix-blend-mode: darken;
        position: absolute;
        top: 0;
        width: inherit;
      }

      .post-image {
        height: inherit;
        object-fit: cover;
        width: inherit;
      }

      .icon-container {
        align-items: center;
        background-color: $darkGrey;
        border-radius: 50px;
        display: flex;
        height: 78px;
        justify-content: center;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 78px;

        .icon {
          filter: invert(100%);
          height: 24px;
          width: 24px;
        }
      }
    }
    
    .text-container {
      margin: 20px;

      .title {
        font-weight: 700;
      }

      p {
        margin: 0 0 10px 0;
      }
    }
  }

  .footer-container {
    display: flex;
    justify-content: flex-end;
    margin: 0 20px 20px 0;

    .checkbox {
      background-color: transparent;
      border: none;
      font-size: 16px;
      position: relative;
      overflow: visible;

      &::before {
        border: 2px solid $grey;
        border-radius: 2px;
        content: "";
        height: 15px;
        left: -22px;
        position: absolute;
        min-width: 15px;
        top: 50%;
        transform: translateY(-50%);
        width: 15px;
      }
    }
  }
}

.full-action-card.complete {
  p {
    color: $textWhite;
  }
}

.full-action-card.seed.complete {
  background-color: $seedBackground;

  .icon-container {
    background-color: $seedBackground;
  }

  a,
  a:visited {
    color: $seed;
  }
}

.full-action-card.sprout.complete {
  background-color: $sproutBackground;

  .icon-container {
    background-color: $sproutBackground;
  }

  a,
  a:visited {
    color: $sprout;
  }
}

.full-action-card.grow.complete {
  background-color: $growBackground;

  .icon-container {
    background-color: $growBackground;
  }

  a,
  a:visited {
    color: $grow;
  }
}

.full-action-card.thrive.complete {
  background-color: $thriveBackground;

  .icon-container {
    background-color: $thriveBackground;
  }

  a,
  a:visited {
    color: $thrive;
  }
}