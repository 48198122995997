.badge-card {
  border-radius: 15px;
  text-decoration: none;
  height: auto;
  width: 45%;
  max-width: 500px;
  transition: transform 0.2s ease-in-out;
  
  &:hover {
    box-shadow: 0px 2px 20px 10px rgba(42, 49, 108, 0.15);
    transform: translateY(-9px);
  }

  .content-container {
    color: $textWhite;
    padding: 30px 40px 20px;

    .heading-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      h2 {
        font-size: 36px;
        font-weight: 600;
        line-height: 49px;
        text-transform: uppercase;
      }  

      .badge {
        img {
          height: 100px;
          width: 100px;
        }
      }
    }

    img.status {
      display: flex;
      margin: 20px 0 0 auto;
    }
  }

  @media screen and (max-width: 970px) {
    max-width: 100%;
    width: 100%;

    .bottom-container {
      align-items: flex-end;
      display: flex;
      flex-direction: row;

      p {
        width: 70%;
      }
      
      img.status {
        margin-bottom: 10px;
      }
    }
  }

  @media screen and (max-width: 600px) {
    .content-container {
      padding: 30px 18px 20px;

      h2,
      p,
      img {
        margin-top: 0;
      }

      .heading-container {
        h2 {
          font-size: 24px;
        }
      }
    }

    img.status {
      height: 40px;
      width: 40px;
    }
  }
}

.badge-card.seed {
  background-color: $seedBackground;
}

.badge-card.sprout {
  background-color: $sproutBackground;
}

.badge-card.grow {
  background-color: $growBackground;
}

.badge-card.thrive {
  background-color: $thriveBackground;
}