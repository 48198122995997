@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');


* {
  font-family: 'Open Sans', sans-serif;
  box-sizing: border-box;
}

body {
  margin: 0;
  position: relative;
}

html,
body,
#root {
  height: 100vh;
  max-width: 100%;
  overflow-x: hidden;
}

.page {
  background-image: linear-gradient(rgba(42, 49, 108, 0.1), rgba(42, 49, 108, 0.1)), url(../assets/background.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
}

p {
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
}

h1, .h1 {
  font-size: 64px;
  font-family: 'Open Sans', sans-serif;
}

h2, .h2 {
  font-size: 40px;
  font-family: 'Open Sans', sans-serif;
}

h3, .h3 {
  font-size: 24px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
}

