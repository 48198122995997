.question-page {
  margin: 0 auto;
  max-width: 1400px;
  padding: 50px 3% 100px;

  .heading-container {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .badge svg {
      width: 80px;
    }
  }

  .question-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-evenly;
    max-width: 1310px;
    padding-top: 80px;

    @media screen and (max-width: 640px) {
      align-items: center;
      flex-direction: column;
    }
  }

  .question-list.hide {
    display: none;
  }
}